import styled from 'styled-components';
import Image from 'gatsby-image';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  max-width: 680px;
  margin: 0 auto;
  padding: 0px 20px 20px 20px;
  border-radius: 2px;
  @media screen and (min-width: 480px) {
    padding: 0px 32px 32px 32px;
  }
`;

export const Cards = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 20px auto;
  padding: 0;
  grid-gap: 25px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.l + 'px'}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
